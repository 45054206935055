import {useEffect, useMemo, useState} from 'react';
import {Actions, useAppDispatch, useAppSelector} from 'store';
import styled from 'styled-components';
import {Button} from 'app/components/Button';
import {AngleLeftIcon} from 'app/icons';
import SubscriptionSideImg from '../../../../images/subscription-side.png'
import HorizontalSub from '../../../../images/horizontal-sub.png'
import SmallHorizontalSub from '../../../../images/small-horizontal-sub.png'
import {CheckoutForm} from 'app/forms/CheckoutForm';
import subscriptionService, {PlanType, SubscriptionFlowPageType} from "../../../../services/subscriptionService";
import {AppLogo} from "../../AppLogo";
import {PaymentFilterButtons} from "../../PaymentFilterButtons";
import CompletedContentStatsIcon from '../../../../images/womanWithCheck.png';
import ShopifyBag from '../../../../images/shopify-bag.png';
import { PlanColumn } from '../PlanColumn';
import { TestimonialsBox } from '../TestimonialsBox';
import { PaymentIntervalFilterButtons } from 'app/components/PaymentIntervalFilterButtons';
import { API } from 'api';
import errorReporter from 'services/errorReporter';
import { useMediaQuery } from 'usehooks-ts';
import AnalyticsEvents from 'services/analyticsEvents';
import { ClientSettings } from '../../../../services/settingsService';
import { TrialInfo } from '../TrialInfo';
import Check from '../../../../images/check.png'
import Cross from '../../../../images/cross.png'
import StarterStar from '../../../../images/starter-star.png'
import Megaphone from '../../../../images/megaphone.png'
import Trophy from '../../../../images/trophy.png'
import {profileAPI} from "../../../../api/profile";

interface Props {
    brand: BrandProfile,
    subscriptions: Subscription[]
    onPageChanged: (page: SubscriptionFlowPageType) => void;
    onCompletedCta: () => void;
    showAllPlans: boolean;
    defaultPlanType?: PlanType;
}

interface Pricing {
    trialDays: number|null,
    monthlyPrice: number,
    yearlyPrice: number,
}

export function SubscriptionModalContent({subscriptions, brand, onPageChanged, onCompletedCta, showAllPlans, defaultPlanType = PlanType.STARTER}: Props) {

    const dispatcher = useAppDispatch();

    const {
        gift: {
            list,
        },
        profile: {representative},
        global: { settings },
    } = useAppSelector((state) => state);

    const [selectedPlanType, setSelectedPlanType] = useState<PlanType>(brand.subscription ? subscriptionService.findUpgradePlan(subscriptionService.findPlanTypeForSubscription(brand.subscription)) || defaultPlanType : defaultPlanType);
    const [currentPage, setCurrentPage] = useState(SubscriptionFlowPageType.THREE_PLAN_PAGE);
    const [selectedPlanFrequency, setSelectedPlanFrequency] = useState<SubscriptionFrequency>('YEAR');

    const smallDevice = useMediaQuery('(max-width: 900px)');

    const analyticsEvents = useMemo(
        () => new AnalyticsEvents(settings as ClientSettings),
        [settings]
    );    

    useEffect(() => {
        onPageChanged(currentPage);
    }, [currentPage]);

    useEffect(() => {
        if (brand?.uid && !brand?.modal_histories["trial-dashboard-payment-modal"]) {
            API.Profile.setModalHistory(brand?.uid, "trial-dashboard-payment-modal").then(() => {
                if (representative?.uid) {
                    dispatcher(Actions.Profile.getProfiles(representative?.uid, brand?.uid)).catch((error: any) => {throw error})
                }
            }).catch((error: any) => {
                errorReporter.report('Error dismissing modal', error);
            });
        }
    }, [brand, representative]);

    const nextPage = (currentPage: SubscriptionFlowPageType) => {
        switch (currentPage) {
            case SubscriptionFlowPageType.THREE_PLAN_PAGE:
                return SubscriptionFlowPageType.FULL_SIZED_PAYMENT_PAGE;
            case SubscriptionFlowPageType.FEATURES:
                return SubscriptionFlowPageType.CHOOSE_PLAN;
            case SubscriptionFlowPageType.CHOOSE_PLAN:
                return SubscriptionFlowPageType.PAYMENT;
            case SubscriptionFlowPageType.PAYMENT:
                return SubscriptionFlowPageType.SUCCESS;
            case SubscriptionFlowPageType.FULL_SIZED_PAYMENT_PAGE:
                return SubscriptionFlowPageType.SUCCESS;
            default:
                return SubscriptionFlowPageType.EXIT;
        }
    }

    const previousPage = (currentPage: SubscriptionFlowPageType) => {
        switch (currentPage) {
            case SubscriptionFlowPageType.CHOOSE_PLAN:
                return SubscriptionFlowPageType.FEATURES;
            case SubscriptionFlowPageType.FULL_SIZED_PAYMENT_PAGE:
                return SubscriptionFlowPageType.THREE_PLAN_PAGE;
            case SubscriptionFlowPageType.PAYMENT:
                return SubscriptionFlowPageType.CHOOSE_PLAN;
            default:
                return SubscriptionFlowPageType.EXIT;
        }
    }

    const onNext = (currentPage: SubscriptionFlowPageType) => {
        const page = nextPage(currentPage);
        page && setCurrentPage(page);
    }

    const onBack = (currentPage: SubscriptionFlowPageType) => {
        const page = previousPage(currentPage);
        if (page > 0) {
            setCurrentPage(page);
        } else {
            dispatcher(Actions.App.setShowPaymentModal(false));
        }
    }

    const featuresPage = useMemo(() => {
        return subscriptionService.featuresPage(brand, subscriptions, selectedPlanType, () => onNext(currentPage), () => onBack(currentPage));
    }, [currentPage, brand, selectedPlanType, subscriptions]);

    const threePlanPage = useMemo(() => {
        return subscriptionService.threePlanPage(brand, subscriptions, selectedPlanType, () => onNext(currentPage), () => onBack(currentPage));
    }, [currentPage, brand, selectedPlanType, subscriptions]);

    const fullSizedPaymentPage = useMemo(() => {
        return subscriptionService.fullSizedPaymentPage(brand, subscriptions, selectedPlanType, () => onNext(currentPage), () => onBack(currentPage));
    }, [currentPage, brand, selectedPlanType, subscriptions]);

    const choosePlanPage = useMemo(() => {
        return subscriptionService.choosePlanPage(brand, subscriptions, selectedPlanType, () => onNext(currentPage), () => onBack(currentPage));
    }, [currentPage, brand, selectedPlanType, subscriptions]);

    const paymentPage = useMemo(() => {
        return subscriptionService.paymentPage(brand, subscriptions, selectedPlanType, () => onNext(currentPage), () => onBack(currentPage));
    }, [currentPage, brand, selectedPlanType, subscriptions]);

    const successPage = useMemo(() => {
        return subscriptionService.successPage(brand, subscriptions, selectedPlanType, list != null, onCompletedCta);
    }, [currentPage, brand, list, selectedPlanType, subscriptions]);

    const subscriptionCandidate = useMemo(() => {
        return subscriptionService.findSubscription(subscriptions, selectedPlanType, selectedPlanFrequency);
    }, [selectedPlanFrequency, selectedPlanType, subscriptions])

    useEffect(() => {
        //@ts-ignore
        if (!window.ga) {return}

        if (currentPage === SubscriptionFlowPageType.CHOOSE_PLAN) {
            //@ts-ignore
            window.ga('event', 'conversion_event_subscription_viewed', {
                'event_callback': () => {},
                'event_timeout': 2000,
            });
        }

        if (currentPage === SubscriptionFlowPageType.SUCCESS) {
            //@ts-ignore
            window.ga('event', 'conversion_event_purchase', {
                'event_callback': () => {},
                'event_timeout': 2000,
            });
        }
    }, [currentPage]);

    const page = useMemo(() => {
        switch (currentPage) {
            case SubscriptionFlowPageType.FEATURES:
                return featuresPage;
            case SubscriptionFlowPageType.CHOOSE_PLAN:
                return choosePlanPage;
            case SubscriptionFlowPageType.THREE_PLAN_PAGE:
                return threePlanPage;
            case SubscriptionFlowPageType.FULL_SIZED_PAYMENT_PAGE:
                return fullSizedPaymentPage;
            case SubscriptionFlowPageType.PAYMENT:
                return paymentPage;
            case SubscriptionFlowPageType.SUCCESS:
                return successPage;
            default:
                throw new Error(`unknown page type ${currentPage}`);
        }
    }, [choosePlanPage, featuresPage, currentPage, paymentPage, successPage])

    const [priceData, setPriceData] = useState<Pricing>({
        trialDays: null,
        monthlyPrice: 0,
        yearlyPrice: 0,
    })

    useEffect(() => {
        window.scrollTo(0,0);
    }, [currentPage])

    useEffect(() => {
        const selectedSubscriptionPlanMonthly = subscriptionService.findSubscription(subscriptions, selectedPlanType, 'MONTH');
        const selectedSubscriptionPlanYearly = subscriptionService.findSubscription(subscriptions, selectedPlanType, 'YEAR');
        setPriceData({
            trialDays: selectedSubscriptionPlanMonthly.trial_days,
            monthlyPrice: selectedSubscriptionPlanMonthly.amount,
            yearlyPrice: selectedSubscriptionPlanYearly.amount,
        });
    }, [selectedPlanType, subscriptions])

    const selectedSubscription = useMemo(() => {
        return subscriptionService.findSubscription(subscriptions, selectedPlanType, selectedPlanFrequency);
    }, [selectedPlanType, selectedPlanFrequency, subscriptions]);

    const isTrialAvailable = useMemo<boolean>(() => subscriptionService.isTrialAvailable(brand, selectedSubscription), [brand]);
    console.log('isTrialAvailable', isTrialAvailable);

    const setPlanAndInterval = (plan: PlanType, interval: SubscriptionFrequency) => {
        setSelectedPlanType(plan)
        setSelectedPlanFrequency(interval);
    }

    const onSuccess = () => {
        analyticsEvents.subscriptionPlansCheckoutSuccess({
            brand_id: brand.uid,
            plan_type: selectedPlanType,
            interval: selectedPlanFrequency,
        });
        page.onNext && page.onNext();
    }

    const onCancel = () => {
        page.onBack && page.onBack()
    }

    const formatDollarValue = (value: number) => {
        if (value % 1 === 0) {
            return value.toFixed(0);
        } else {
            return value.toFixed(2);
        }
    }


    const [newTotal, setNewTotal] = useState<number|undefined>(undefined);
    const [trialDays, setTrialDays] = useState<number|undefined>(undefined);
    useEffect(() => {
        setNewTotal(undefined)
    }, [page])
    const [previewError, setPreviewError] = useState('');
    const previewSubscription = (brand: BrandProfile, code: string, coupon: string) => {
        if (!brand.uid || !code) {return}
        setPreviewError('');
        API.Profile.previewSubscription(brand, code, coupon).then((data: SubscriptionPreviewResponse|void) => {
            if (data) {
                if (data.trial_days && !selectedSubscription.trial_days) {
                    setNewTotal(data.amount_due as number);
                } else if (!data.trial_days) {
                    setNewTotal(data.amount_due as number);
                }
                if (data.trial_days) {
                    setTrialDays(data.trial_days as number);
                } else {
                    setTrialDays(undefined);
                }
            } else {
                // no coupon present on a new subscription. do nothing.
                setNewTotal(undefined);
                setTrialDays(undefined);
                return
            }
        }).catch((error: any) => {
            if (error.response.status != 404) {
                errorReporter.report('Preview subscription didnt work.', error);
            }
            setNewTotal(undefined);
            setTrialDays(undefined);
            setPreviewError(error.response.data.message as string);
        })
    }

    const trialEndDate = useMemo(() => {
        const today = new Date();
        const trial_days = trialDays || selectedSubscription.trial_days;
        if (!trial_days) {

            // throw new Error('subscription ' + selectedSubscription.code + ' does not have trial_days');
            return;
        }
        const twoWeeksFromToday = new Date(today.getTime() + trial_days * 24 * 60 * 60 * 1000);
        return twoWeeksFromToday.toLocaleDateString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric"
        });
    }, [trialDays]);

    const currentPlan = useMemo(() => {
        if (brand.subscription) {
            return subscriptionService.findPlanTypeForSubscription(brand.subscription);
        } else {
            return undefined;
        }
    }, [brand.subscription]);

    const currentPlanFrequency = useMemo(() => {
        if (brand.subscription) {
            return brand.subscription.subscription.frequency;
        } else {
            return undefined;
        }
    }, [brand.subscription]);

    const getSubscriptionPlanName = (planType: PlanType) => {
        if(planType === 'Starter'){
            return 'Basic'
        }
        return planType;
    }

    const paymentNextPageBtn = (plan: PlanType, interval: SubscriptionFrequency) => {
        // if (!brand.subscription?.canceled_at && brand.subscriptionv2?.status !== "incomplete" && brand.subscriptionv2?.status !== 'canceled' && currentPlan === plan && currentPlanFrequency === interval) {
        //     return;
        // }
        setPlanAndInterval(plan, interval)
        onNext(currentPage);
    }

    return (
        <>
            {currentPage === SubscriptionFlowPageType.SUCCESS ? (
                <></>
            ) : (
                <>
                    {page.twoColumns && !page.fullSized ? (
                        <TwoColumns>
                            <LeftColumn>
                                {page.showBackBtn && (
                                    <Button className="back-btn" theme="aqua" iconOnly={true} onClick={() => page.onBack && page.onBack()}>
                                        <AngleLeftIcon /> Back
                                    </Button>
                                )}

                                <TitleText>{page.titleText}</TitleText>

                                <TitleSubText>{page.titleSubtext}</TitleSubText>

                                {page.showFilters && (
                                    <>
                                        <FilterBtnRow>
                                            <PaymentFilterButtons
                                                brandSubscription={brand.subscription}
                                                changeFilterItems={(val) => setSelectedPlanType(val)}
                                                currentlySelected={selectedPlanType}
                                                showAllPlans={showAllPlans}
                                            />
                                        </FilterBtnRow>
                                    </>
                                
                                )}



                                {page.featureDescriptionText && currentPage !== SubscriptionFlowPageType.PAYMENT && (
                                    <FeatureDescriptionText>{page.featureDescriptionText[0]} <BoldP>{page.featureDescriptionText[1]}</BoldP></FeatureDescriptionText>
                                )}

                                {(currentPage !== SubscriptionFlowPageType.PAYMENT && currentPage !== SubscriptionFlowPageType.FULL_SIZED_PAYMENT_PAGE && currentPage !== SubscriptionFlowPageType.THREE_PLAN_PAGE && currentPage !== SubscriptionFlowPageType.CHOOSE_PLAN) && page.campaignFeatureText && (
                                    <>

                                        <FeatureItem>
                                            <FeatureIcon>
                                                <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M31.5161 5.71048C39.0446 5.77498 45.2076 11.9045 45.3111 19.4325C45.3521 22.415 44.4541 25.1855 42.8946 27.468L43.9861 30.1255C44.0746 30.341 43.8806 30.566 43.6541 30.5095L40.8746 29.8165C38.3801 32.1385 35.0326 33.5565 31.3536 33.546C23.8716 33.5245 17.5416 27.2325 17.4771 19.751C17.4096 11.9675 23.7326 5.64398 31.5161 5.71048Z" fill="#FF855C"/>
                                                    <path d="M20.3648 13.4385C11.9073 13.4385 5.05078 20.2945 5.05078 28.7525C5.05078 31.9525 6.03278 34.9225 7.71078 37.3795L6.27478 40.8755L9.93328 39.9635C12.6678 42.509 16.3338 44.067 20.3648 44.067C28.8228 44.067 35.6788 37.2105 35.6788 28.753C35.6788 20.2955 28.8228 13.4385 20.3648 13.4385Z" fill="white"/>
                                                    <path d="M20.3649 44.7816C16.4299 44.7816 12.6709 43.3526 9.73594 40.7496L6.44794 41.5691C6.18644 41.6346 5.91044 41.5471 5.73394 41.3431C5.55744 41.1391 5.51094 40.8536 5.61344 40.6041L6.90494 37.4596C5.22294 34.8646 4.33594 31.8626 4.33594 28.7526C4.33594 19.9141 11.5264 12.7236 20.3649 12.7236C29.2034 12.7236 36.3939 19.9141 36.3939 28.7526C36.3944 37.5911 29.2039 44.7816 20.3649 44.7816ZM9.93394 39.2481C10.1124 39.2481 10.2874 39.3151 10.4209 39.4396C13.1309 41.9621 16.6624 43.3516 20.3649 43.3516C28.4149 43.3516 34.9639 36.8026 34.9639 28.7526C34.9639 20.7026 28.4149 14.1536 20.3649 14.1536C12.3149 14.1536 5.76594 20.7026 5.76594 28.7526C5.76594 31.7041 6.64294 34.5476 8.30144 36.9761C8.43744 37.1746 8.46394 37.4286 8.37244 37.6511L7.47344 39.8396L9.76094 39.2696C9.81794 39.2551 9.87594 39.2481 9.93394 39.2481Z" fill="#262262"/>
                                                    <path d="M14.9927 32.3893C14.5672 32.3893 14.2227 32.0448 14.2227 31.6193V25.9233C14.2227 25.4978 14.5672 25.1533 14.9927 25.1533C15.4182 25.1533 15.7627 25.4978 15.7627 25.9233V31.6193C15.7627 32.0448 15.4182 32.3893 14.9927 32.3893Z" fill="#262262"/>
                                                    <path d="M18.7192 32.3893C18.2937 32.3893 17.9492 32.0448 17.9492 31.6193V25.9233C17.9492 25.4978 18.2937 25.1533 18.7192 25.1533C19.1447 25.1533 19.4892 25.4978 19.4892 25.9233V31.6193C19.4892 32.0448 19.1442 32.3893 18.7192 32.3893Z" fill="#262262"/>
                                                    <path d="M21.6411 32.3893C21.2156 32.3893 20.8711 32.0448 20.8711 31.6193V25.9233C20.8711 25.4978 21.2156 25.1533 21.6411 25.1533C22.0666 25.1533 22.4111 25.4978 22.4111 25.9233V31.6193C22.4111 32.0448 22.0661 32.3893 21.6411 32.3893Z" fill="#262262"/>
                                                    <path d="M24.7856 29.9403C24.3601 29.9403 24.0156 29.5958 24.0156 29.1703V25.9233C24.0156 25.4978 24.3601 25.1533 24.7856 25.1533C25.2111 25.1533 25.5556 25.4978 25.5556 25.9233V29.1703C25.5556 29.5958 25.2106 29.9403 24.7856 29.9403Z" fill="#262262"/>
                                                    <path d="M24.7856 32.3537C24.3601 32.3537 24.0156 32.0092 24.0156 31.5837V31.5727C24.0156 31.1472 24.3601 30.8027 24.7856 30.8027C25.2111 30.8027 25.5556 31.1472 25.5556 31.5727V31.5837C25.5556 32.0092 25.2106 32.3537 24.7856 32.3537Z" fill="#262262"/>
                                                    <path d="M18.7172 29.4267H14.9927C14.5672 29.4267 14.2227 29.0822 14.2227 28.6567C14.2227 28.2312 14.5672 27.8867 14.9927 27.8867H18.7172C19.1427 27.8867 19.4872 28.2312 19.4872 28.6567C19.4872 29.0822 19.1422 29.4267 18.7172 29.4267Z" fill="#262262"/>
                                                </svg>
                                            </FeatureIcon>
                                            <FeatureItemText>
                                                {page.campaignFeatureText[0]}
                                                <BoldP>{page.campaignFeatureText[1]}</BoldP>
                                                {page.campaignFeatureText[2]}
                                            </FeatureItemText>
                                        </FeatureItem>

                                        <FeatureItem className="right-column-left-align">
                                            <FeatureIcon>
                                                <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M44.3862 11.8613H21.7312C20.3802 11.8613 19.2852 12.9563 19.2852 14.3073V31.1118C19.2852 32.4628 20.3802 33.5578 21.7312 33.5578H30.7842L33.7537 36.9973L36.8182 33.5578H44.3862C45.7372 33.5578 46.8322 32.4628 46.8322 31.1118V14.3073C46.8327 12.9563 45.7372 11.8613 44.3862 11.8613Z" fill="white"/>
                                                    <path d="M33.7541 37.7678C33.7521 37.7678 33.7501 37.7678 33.7481 37.7678C33.5266 37.7663 33.3161 37.6688 33.1711 37.5008L30.4321 34.3283H21.7316C19.9581 34.3283 18.5156 32.8853 18.5156 31.1123V14.3078C18.5156 12.5343 19.9586 11.0918 21.7316 11.0918H44.3861C46.1596 11.0918 47.6021 12.5348 47.6021 14.3078V31.1123C47.6021 32.8858 46.1591 34.3283 44.3861 34.3283H37.1636L34.3291 37.5098C34.1826 37.6738 33.9736 37.7678 33.7541 37.7678ZM21.7316 12.6318C20.8071 12.6318 20.0556 13.3838 20.0556 14.3078V31.1123C20.0556 32.0368 20.8076 32.7883 21.7316 32.7883H30.7846C31.0086 32.7883 31.2211 32.8858 31.3676 33.0553L33.7631 35.8303L36.2436 33.0463C36.3896 32.8823 36.5986 32.7883 36.8186 32.7883H44.3866C45.3111 32.7883 46.0626 32.0363 46.0626 31.1123V14.3078C46.0626 13.3833 45.3106 12.6318 44.3866 12.6318H21.7316Z" fill="#262262"/>
                                                    <path d="M38.893 21.6113H27.227C26.8015 21.6113 26.457 21.2668 26.457 20.8413C26.457 20.4158 26.8015 20.0713 27.227 20.0713H38.893C39.3185 20.0713 39.663 20.4158 39.663 20.8413C39.663 21.2668 39.318 21.6113 38.893 21.6113Z" fill="#262262"/>
                                                    <path d="M36.8029 25.6816H29.3169C28.8914 25.6816 28.5469 25.3371 28.5469 24.9116C28.5469 24.4861 28.8914 24.1416 29.3169 24.1416H36.8029C37.2284 24.1416 37.5729 24.4861 37.5729 24.9116C37.5729 25.3371 37.2279 25.6816 36.8029 25.6816Z" fill="#262262"/>
                                                    <path d="M16.8688 19.9365C10.5833 19.9365 5.48828 25.032 5.48828 31.317C5.48828 33.695 6.21828 35.902 7.46528 37.728L6.39828 40.326L9.11728 39.648C11.1493 41.5395 13.8738 42.6975 16.8693 42.6975C23.1548 42.6975 28.2498 37.602 28.2498 31.317C28.2498 25.032 23.1543 19.9365 16.8688 19.9365Z" fill="white"/>
                                                    <path d="M16.8694 43.4127C13.9324 43.4127 11.1254 42.3577 8.92144 40.4342L6.57144 41.0202C6.30994 41.0852 6.03394 40.9982 5.85744 40.7942C5.68094 40.5902 5.63444 40.3042 5.73694 40.0552L6.66044 37.8072C5.42494 35.8677 4.77344 33.6327 4.77344 31.3182C4.77344 24.6487 10.1994 19.2227 16.8689 19.2227C23.5384 19.2227 28.9644 24.6487 28.9644 31.3182C28.9649 37.9867 23.5389 43.4127 16.8694 43.4127ZM9.11744 38.9332C9.29594 38.9332 9.47094 39.0002 9.60444 39.1247C11.5844 40.9677 14.1644 41.9827 16.8694 41.9827C22.7504 41.9827 27.5349 37.1982 27.5349 31.3172C27.5349 25.4362 22.7504 20.6517 16.8694 20.6517C10.9884 20.6517 6.20394 25.4362 6.20394 31.3172C6.20394 33.4737 6.84444 35.5507 8.05644 37.3252C8.19244 37.5237 8.21894 37.7777 8.12744 38.0002L7.59744 39.2907L8.94494 38.9547C9.00194 38.9402 9.05994 38.9332 9.11744 38.9332Z" fill="#262262"/>
                                                    <path d="M18.9716 22.6561C23.2728 22.6561 26.7596 19.1693 26.7596 14.8681C26.7596 10.5669 23.2728 7.08008 18.9716 7.08008C14.6704 7.08008 11.1836 10.5669 11.1836 14.8681C11.1836 19.1693 14.6704 22.6561 18.9716 22.6561Z" fill="#FFD15C"/>
                                                    <path d="M19.2849 18.1584C18.8594 18.1584 18.5149 17.8139 18.5149 17.3884V12.8574H18.0669C17.6414 12.8574 17.2969 12.5129 17.2969 12.0874C17.2969 11.6619 17.6414 11.3174 18.0669 11.3174H19.2849C19.7104 11.3174 20.0549 11.6619 20.0549 12.0874V17.3884C20.0549 17.8134 19.7099 18.1584 19.2849 18.1584Z" fill="#262262"/>
                                                    <path d="M11.7041 32.4921C12.4855 32.4921 13.1191 31.8586 13.1191 31.0771C13.1191 30.2956 12.4855 29.6621 11.7041 29.6621C10.9226 29.6621 10.2891 30.2956 10.2891 31.0771C10.2891 31.8586 10.9226 32.4921 11.7041 32.4921Z" fill="#FFD15C"/>
                                                    <path d="M17.2041 32.4921C17.9855 32.4921 18.6191 31.8586 18.6191 31.0771C18.6191 30.2956 17.9855 29.6621 17.2041 29.6621C16.4226 29.6621 15.7891 30.2956 15.7891 31.0771C15.7891 31.8586 16.4226 32.4921 17.2041 32.4921Z" fill="#FFD15C"/>
                                                    <path d="M22.7041 32.4921C23.4855 32.4921 24.1191 31.8586 24.1191 31.0771C24.1191 30.2956 23.4855 29.6621 22.7041 29.6621C21.9226 29.6621 21.2891 30.2956 21.2891 31.0771C21.2891 31.8586 21.9226 32.4921 22.7041 32.4921Z" fill="#FFD15C"/>
                                                </svg>
                                            </FeatureIcon>
                                            <FeatureItemText>
                                                {page.giftCreatorsFeatureText[0]}
                                                <BoldP>{page.giftCreatorsFeatureText[1]}</BoldP>
                                                {page.giftCreatorsFeatureText[2]}
                                            </FeatureItemText>
                                        </FeatureItem>

                                        <FeatureItem>
                                            <FeatureIcon>
                                                <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M18.9736 29.9834H8.05859V45.8059H18.9736V29.9834Z" fill="white"/>
                                                    <path d="M31.0252 16.1406H18.9727V45.8061H31.0252V16.1406Z" fill="white"/>
                                                    <path d="M41.9423 23.2402H31.0273V45.8057H41.9423V23.2402Z" fill="white"/>
                                                    <path d="M25.4652 10.3302L23.3097 11.2432C23.0402 11.3572 22.7457 11.1437 22.7712 10.8517L22.9737 8.51971L21.4392 6.75221C21.2472 6.53121 21.3597 6.18521 21.6452 6.11921L23.9257 5.59121L25.1327 3.58571C25.2837 3.33471 25.6477 3.33471 25.7987 3.58571L27.0057 5.59121L29.2862 6.11921C29.5717 6.18521 29.6837 6.53121 29.4922 6.75221L27.9577 8.51971L28.1602 10.8517C28.1857 11.1437 27.8912 11.3572 27.6217 11.2432L25.4652 10.3302Z" fill="#EFC5E6"/>
                                                    <path d="M25.7036 35.2238C25.2636 35.2238 24.9071 34.8673 24.9071 34.4273V29.0813H24.2926C23.8526 29.0813 23.4961 28.7248 23.4961 28.2848C23.4961 27.8448 23.8526 27.4883 24.2926 27.4883H25.7036C26.1436 27.4883 26.5001 27.8448 26.5001 28.2848V34.4273C26.5001 34.8668 26.1436 35.2238 25.7036 35.2238Z" fill="#262262"/>
                                                    <path d="M18.9736 29.9834H8.05859V35.6134H18.9736V29.9834Z" fill="#EFC5E6"/>
                                                    <path d="M30.7517 16.1406H18.6992V21.7706H30.7517V16.1406Z" fill="#EFC5E6"/>
                                                    <path d="M42.2861 23.2402H31.3711V28.8702H42.2861V23.2402Z" fill="#EFC5E6"/>
                                                    <path d="M18.9732 46.6025H8.05822C7.61822 46.6025 7.26172 46.246 7.26172 45.806V29.983C7.26172 29.543 7.61822 29.1865 8.05822 29.1865H18.9732C19.4132 29.1865 19.7697 29.543 19.7697 29.983V45.8055C19.7697 46.2455 19.4132 46.6025 18.9732 46.6025ZM8.85522 45.009H18.1767V30.7795H8.85522V45.009Z" fill="#262262"/>
                                                    <path d="M31.0253 46.6027H18.9723C18.5323 46.6027 18.1758 46.2462 18.1758 45.8062V16.1402C18.1758 15.7002 18.5323 15.3438 18.9723 15.3438H31.0248C31.4648 15.3438 31.8213 15.7002 31.8213 16.1402V45.8057C31.8218 46.2457 31.4653 46.6027 31.0253 46.6027ZM19.7688 45.0092H30.2288V16.9367H19.7688V45.0092Z" fill="#262262"/>
                                                    <path d="M41.942 46.6028H31.027C30.587 46.6028 30.2305 46.2463 30.2305 45.8063V23.2408C30.2305 22.8008 30.587 22.4443 31.027 22.4443H41.942C42.382 22.4443 42.7385 22.8008 42.7385 23.2408V45.8063C42.7385 46.2458 42.382 46.6028 41.942 46.6028ZM31.824 45.0093H41.1455V24.0373H31.824V45.0093Z" fill="#262262"/>
                                                </svg>

                                            </FeatureIcon>
                                            <FeatureItemText>
                                                {page.matchCreatorsFeatureText[0]}
                                                <BoldP>{page.matchCreatorsFeatureText[1]}</BoldP>
                                                {page.matchCreatorsFeatureText[2]}
                                            </FeatureItemText>
                                        </FeatureItem>

                                        <FeatureItem className="right-column-left-align">
                                            <FeatureIcon>
                                                <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M39.2628 16.7109H12.1948C10.6998 16.7109 9.48828 17.9229 9.48828 19.4174V38.0119C9.48828 39.5069 10.7003 40.7184 12.1948 40.7184H39.2628C40.7578 40.7184 41.9693 39.5064 41.9693 38.0119V19.4174C41.9698 17.9224 40.7578 16.7109 39.2628 16.7109Z" fill="white"/>
                                                    <path d="M39.2633 41.4894H12.1953C10.2783 41.4894 8.71875 39.9299 8.71875 38.0129V19.4179C8.71875 17.5009 10.2783 15.9414 12.1953 15.9414H39.2633C41.1803 15.9414 42.7397 17.5009 42.7397 19.4179V38.0124C42.7402 39.9294 41.1803 41.4894 39.2633 41.4894ZM12.1953 17.4814C11.1273 17.4814 10.2588 18.3504 10.2588 19.4179V38.0124C10.2588 39.0804 11.1278 39.9489 12.1953 39.9489H39.2633C40.3313 39.9489 41.1997 39.0799 41.1997 38.0124V19.4179C41.1997 18.3499 40.3308 17.4814 39.2633 17.4814H12.1953Z" fill="#262262"/>
                                                    <path d="M35.3643 12.9111H8.29634C6.80134 12.9111 5.58984 14.1231 5.58984 15.6176V34.2121C5.58984 35.7071 6.80184 36.9186 8.29634 36.9186H35.3643C36.8593 36.9186 38.0708 35.7066 38.0708 34.2121V15.6176C38.0713 14.1226 36.8593 12.9111 35.3643 12.9111Z" fill="white"/>
                                                    <path d="M35.3648 37.6896H8.29681C6.37981 37.6896 4.82031 36.1301 4.82031 34.2131V15.6181C4.82031 13.7011 6.37981 12.1416 8.29681 12.1416H35.3648C37.2818 12.1416 38.8413 13.7011 38.8413 15.6181V34.2126C38.8418 36.1296 37.2818 37.6896 35.3648 37.6896ZM8.29681 13.6816C7.22881 13.6816 6.36031 14.5506 6.36031 15.6181V34.2126C6.36031 35.2806 7.22931 36.1491 8.29681 36.1491H35.3648C36.4328 36.1491 37.3013 35.2801 37.3013 34.2126V15.6181C37.3013 14.5501 36.4323 13.6816 35.3648 13.6816H8.29681Z" fill="#262262"/>
                                                    <path d="M40.0156 22.0039C43.6055 22.0039 46.5156 19.0938 46.5156 15.5039C46.5156 11.9141 43.6055 9.00391 40.0156 9.00391C36.4258 9.00391 33.5156 11.9141 33.5156 15.5039C33.5156 19.0938 36.4258 22.0039 40.0156 22.0039Z" fill="#EFC5E6"/>
                                                    <path d="M39.5659 17.8125C39.3729 17.8125 39.1879 17.7345 39.0529 17.5955L37.3934 15.8865C37.1184 15.603 37.1249 15.1505 37.4084 14.8755C37.6919 14.6005 38.1444 14.6075 38.4194 14.8905L39.5154 16.0195L41.5594 13.4795C41.8069 13.172 42.2569 13.1235 42.5649 13.3705C42.8724 13.618 42.9214 14.068 42.6739 14.376L40.1229 17.546C39.9944 17.7055 39.8039 17.8025 39.5989 17.812C39.5879 17.8125 39.5769 17.8125 39.5659 17.8125Z" fill="white"/>
                                                    <path d="M19.026 23.6057C20.352 23.6057 21.427 22.5307 21.427 21.2047C21.427 19.8787 20.352 18.8037 19.026 18.8037C17.7 18.8037 16.625 19.8787 16.625 21.2047C16.625 22.5307 17.7 23.6057 19.026 23.6057Z" fill="#EFC5E6"/>
                                                    <path d="M9.82031 32.2269H34.0813L25.3188 23.4639L19.5828 29.1999L15.6983 25.3154L9.82031 32.2269Z" fill="#EFC5E6"/>
                                                </svg>

                                            </FeatureIcon>
                                            <FeatureItemText>
                                                {page.captureStoriesFeaturesText}
                                            </FeatureItemText>
                                        </FeatureItem>

                                        <FeatureItem>
                                            <FeatureIcon>
                                                <img src={ShopifyBag} />
                                            </FeatureIcon>

                                            <FeatureItemText>Connect your Shopify store</FeatureItemText>
                                        </FeatureItem>

                                        {page.completedContentStatsText[0] && (
                                            <FeatureItem>
                                                <FeatureIcon>
                                                    <img style={{transform: 'translateX(6px)'}} src={CompletedContentStatsIcon} />
                                                </FeatureIcon>

                                                <FeatureItemText>{page.completedContentStatsText[0]}</FeatureItemText>
                                            </FeatureItem>
                                        )}

                                    </>
                                )}


                                {currentPage === SubscriptionFlowPageType.CHOOSE_PLAN && priceData.monthlyPrice && priceData.yearlyPrice && (
                                    <>
                                        <PriceOption onClick={() => setSelectedPlanFrequency('YEAR')}>
                                            <input value="YEARLY" type="radio" checked={selectedPlanFrequency === 'YEAR'} onChange={() => {}} />
                                            <PriceOptionContent>
                                                <PriceOptionAmount>Yearly <PriceSpan>Save ${ formatDollarValue(Math.floor((priceData.monthlyPrice * 12) - priceData.yearlyPrice)) }</PriceSpan></PriceOptionAmount>
                                                <PriceOptionSubtext>A${formatDollarValue(priceData.yearlyPrice)} (A${formatDollarValue(Math.floor(priceData.yearlyPrice / 12))}/month) {isTrialAvailable && priceData.trialDays && `after ${priceData.trialDays} day trial`}</PriceOptionSubtext>
                                            </PriceOptionContent>
                                        </PriceOption>

                                        <PriceOption onClick={() => setSelectedPlanFrequency('MONTH')}>
                                            <input value="MONTHLY" type="radio" checked={selectedPlanFrequency === 'MONTH'} onChange={() => {}} />
                                            <PriceOptionContent>
                                                <PriceOptionAmount>Monthly</PriceOptionAmount>
                                                <PriceOptionSubtext>A${formatDollarValue(priceData.monthlyPrice)}</PriceOptionSubtext>
                                            </PriceOptionContent>
                                        </PriceOption>


                                        <LeftSectionFooter style={{marginTop: page.showFilters ? '125px' : '190px'}}>
                                            <DueBlock>
                                                {isTrialAvailable && (
                                                    <DueRow>
                                                        <p>Due {trialEndDate}</p>
                                                        <p>A${formatDollarValue(selectedSubscription.amount)}</p>
                                                    </DueRow>
                                                )}
                                                {isTrialAvailable && settings?.features["subscriptions.trials"] && (
                                                    <DueRow>
                                                        <p className="due-row-bolder">Due today - <span className="due-row-support">{subscriptionCandidate.trial_days} Days free</span></p>
                                                        <p className="due-row-bolder">A$0</p>
                                                    </DueRow>
                                                )}
                                                {!isTrialAvailable && (
                                                    <DueRow>
                                                        <p className="due-row-bolder">Due today</p>
                                                        <p className="due-row-bolder">A${parseFloat(selectedSubscription.amount.toFixed(2)).toLocaleString()}</p>
                                                    </DueRow>
                                                )}
                                            </DueBlock>
                                        </LeftSectionFooter>
                                    </>
                                )}

                                {currentPage === SubscriptionFlowPageType.PAYMENT && brand && (
                                    <>
                                        <PaymentInputFields>

                                            <CheckoutForm
                                                brand={brand}
                                                interval={selectedPlanFrequency}
                                                plan={selectedPlanType}
                                                previewSubscription={previewSubscription}
                                                shownInModal={true}
                                                showLoader={false}
                                                code={selectedSubscription.code}
                                                onSuccess={onSuccess}
                                                onCancel={onCancel}
                                            />
                                        </PaymentInputFields>

                                        <LeftSectionFooter style={{marginTop: '-81px'}}>
                                            <DueBlock>
                                                <DueRow>
                                                    <p>Due {isTrialAvailable ? trialEndDate : 'today'}</p>
                                                    <p>A${parseFloat(selectedSubscription.amount.toFixed(2)).toLocaleString()}</p>
                                                </DueRow>
                                                {isTrialAvailable && settings?.features["subscriptions.trials"] && (
                                                    <DueRow>
                                                        <p className="due-row-bolder">Due today - <span className="due-row-support">{subscriptionCandidate.trial_days} Days free</span></p>
                                                        <p className="due-row-bolder">A$0</p>
                                                    </DueRow>
                                                )}
                                            </DueBlock>
                                        </LeftSectionFooter>

                                    </>
                                )}

                                {currentPage !== SubscriptionFlowPageType.PAYMENT && (
                                    <Button className={`cta-btn absoluted`} onClick={() => page.onNext && page.onNext()}>{page.nextButtonText}</Button>
                                )}

                            </LeftColumn>

                            <RightColumn>
                                {(currentPage === SubscriptionFlowPageType.PAYMENT || currentPage === SubscriptionFlowPageType.CHOOSE_PLAN) ? (

                                    <>

                                        <AppLogo className="gifted-logo" />

                                        <img src={SmallHorizontalSub} width="436" />

                                        <div>
                                            <FeatureDescriptionText className="right-column-left-align">Here's what you get when you upgrade to <BoldP>{selectedPlanType}</BoldP></FeatureDescriptionText>

                                            <FeatureItem className="right-column-left-align">
                                                <FeatureIcon>
                                                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M31.5161 5.71048C39.0446 5.77498 45.2076 11.9045 45.3111 19.4325C45.3521 22.415 44.4541 25.1855 42.8946 27.468L43.9861 30.1255C44.0746 30.341 43.8806 30.566 43.6541 30.5095L40.8746 29.8165C38.3801 32.1385 35.0326 33.5565 31.3536 33.546C23.8716 33.5245 17.5416 27.2325 17.4771 19.751C17.4096 11.9675 23.7326 5.64398 31.5161 5.71048Z" fill="#FF855C"/>
                                                        <path d="M20.3648 13.4385C11.9073 13.4385 5.05078 20.2945 5.05078 28.7525C5.05078 31.9525 6.03278 34.9225 7.71078 37.3795L6.27478 40.8755L9.93328 39.9635C12.6678 42.509 16.3338 44.067 20.3648 44.067C28.8228 44.067 35.6788 37.2105 35.6788 28.753C35.6788 20.2955 28.8228 13.4385 20.3648 13.4385Z" fill="white"/>
                                                        <path d="M20.3649 44.7816C16.4299 44.7816 12.6709 43.3526 9.73594 40.7496L6.44794 41.5691C6.18644 41.6346 5.91044 41.5471 5.73394 41.3431C5.55744 41.1391 5.51094 40.8536 5.61344 40.6041L6.90494 37.4596C5.22294 34.8646 4.33594 31.8626 4.33594 28.7526C4.33594 19.9141 11.5264 12.7236 20.3649 12.7236C29.2034 12.7236 36.3939 19.9141 36.3939 28.7526C36.3944 37.5911 29.2039 44.7816 20.3649 44.7816ZM9.93394 39.2481C10.1124 39.2481 10.2874 39.3151 10.4209 39.4396C13.1309 41.9621 16.6624 43.3516 20.3649 43.3516C28.4149 43.3516 34.9639 36.8026 34.9639 28.7526C34.9639 20.7026 28.4149 14.1536 20.3649 14.1536C12.3149 14.1536 5.76594 20.7026 5.76594 28.7526C5.76594 31.7041 6.64294 34.5476 8.30144 36.9761C8.43744 37.1746 8.46394 37.4286 8.37244 37.6511L7.47344 39.8396L9.76094 39.2696C9.81794 39.2551 9.87594 39.2481 9.93394 39.2481Z" fill="#262262"/>
                                                        <path d="M14.9927 32.3893C14.5672 32.3893 14.2227 32.0448 14.2227 31.6193V25.9233C14.2227 25.4978 14.5672 25.1533 14.9927 25.1533C15.4182 25.1533 15.7627 25.4978 15.7627 25.9233V31.6193C15.7627 32.0448 15.4182 32.3893 14.9927 32.3893Z" fill="#262262"/>
                                                        <path d="M18.7192 32.3893C18.2937 32.3893 17.9492 32.0448 17.9492 31.6193V25.9233C17.9492 25.4978 18.2937 25.1533 18.7192 25.1533C19.1447 25.1533 19.4892 25.4978 19.4892 25.9233V31.6193C19.4892 32.0448 19.1442 32.3893 18.7192 32.3893Z" fill="#262262"/>
                                                        <path d="M21.6411 32.3893C21.2156 32.3893 20.8711 32.0448 20.8711 31.6193V25.9233C20.8711 25.4978 21.2156 25.1533 21.6411 25.1533C22.0666 25.1533 22.4111 25.4978 22.4111 25.9233V31.6193C22.4111 32.0448 22.0661 32.3893 21.6411 32.3893Z" fill="#262262"/>
                                                        <path d="M24.7856 29.9403C24.3601 29.9403 24.0156 29.5958 24.0156 29.1703V25.9233C24.0156 25.4978 24.3601 25.1533 24.7856 25.1533C25.2111 25.1533 25.5556 25.4978 25.5556 25.9233V29.1703C25.5556 29.5958 25.2106 29.9403 24.7856 29.9403Z" fill="#262262"/>
                                                        <path d="M24.7856 32.3537C24.3601 32.3537 24.0156 32.0092 24.0156 31.5837V31.5727C24.0156 31.1472 24.3601 30.8027 24.7856 30.8027C25.2111 30.8027 25.5556 31.1472 25.5556 31.5727V31.5837C25.5556 32.0092 25.2106 32.3537 24.7856 32.3537Z" fill="#262262"/>
                                                        <path d="M18.7172 29.4267H14.9927C14.5672 29.4267 14.2227 29.0822 14.2227 28.6567C14.2227 28.2312 14.5672 27.8867 14.9927 27.8867H18.7172C19.1427 27.8867 19.4872 28.2312 19.4872 28.6567C19.4872 29.0822 19.1422 29.4267 18.7172 29.4267Z" fill="#262262"/>
                                                    </svg>
                                                </FeatureIcon>
                                                <FeatureItemText>
                                                    {page.campaignFeatureText[0]}
                                                    <BoldP>{page.campaignFeatureText[1]}</BoldP>
                                                    {page.campaignFeatureText[2]}
                                                </FeatureItemText>
                                            </FeatureItem>

                                            <FeatureItem className="right-column-left-align">
                                                <FeatureIcon>
                                                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M44.3862 11.8613H21.7312C20.3802 11.8613 19.2852 12.9563 19.2852 14.3073V31.1118C19.2852 32.4628 20.3802 33.5578 21.7312 33.5578H30.7842L33.7537 36.9973L36.8182 33.5578H44.3862C45.7372 33.5578 46.8322 32.4628 46.8322 31.1118V14.3073C46.8327 12.9563 45.7372 11.8613 44.3862 11.8613Z" fill="white"/>
                                                        <path d="M33.7541 37.7678C33.7521 37.7678 33.7501 37.7678 33.7481 37.7678C33.5266 37.7663 33.3161 37.6688 33.1711 37.5008L30.4321 34.3283H21.7316C19.9581 34.3283 18.5156 32.8853 18.5156 31.1123V14.3078C18.5156 12.5343 19.9586 11.0918 21.7316 11.0918H44.3861C46.1596 11.0918 47.6021 12.5348 47.6021 14.3078V31.1123C47.6021 32.8858 46.1591 34.3283 44.3861 34.3283H37.1636L34.3291 37.5098C34.1826 37.6738 33.9736 37.7678 33.7541 37.7678ZM21.7316 12.6318C20.8071 12.6318 20.0556 13.3838 20.0556 14.3078V31.1123C20.0556 32.0368 20.8076 32.7883 21.7316 32.7883H30.7846C31.0086 32.7883 31.2211 32.8858 31.3676 33.0553L33.7631 35.8303L36.2436 33.0463C36.3896 32.8823 36.5986 32.7883 36.8186 32.7883H44.3866C45.3111 32.7883 46.0626 32.0363 46.0626 31.1123V14.3078C46.0626 13.3833 45.3106 12.6318 44.3866 12.6318H21.7316Z" fill="#262262"/>
                                                        <path d="M38.893 21.6113H27.227C26.8015 21.6113 26.457 21.2668 26.457 20.8413C26.457 20.4158 26.8015 20.0713 27.227 20.0713H38.893C39.3185 20.0713 39.663 20.4158 39.663 20.8413C39.663 21.2668 39.318 21.6113 38.893 21.6113Z" fill="#262262"/>
                                                        <path d="M36.8029 25.6816H29.3169C28.8914 25.6816 28.5469 25.3371 28.5469 24.9116C28.5469 24.4861 28.8914 24.1416 29.3169 24.1416H36.8029C37.2284 24.1416 37.5729 24.4861 37.5729 24.9116C37.5729 25.3371 37.2279 25.6816 36.8029 25.6816Z" fill="#262262"/>
                                                        <path d="M16.8688 19.9365C10.5833 19.9365 5.48828 25.032 5.48828 31.317C5.48828 33.695 6.21828 35.902 7.46528 37.728L6.39828 40.326L9.11728 39.648C11.1493 41.5395 13.8738 42.6975 16.8693 42.6975C23.1548 42.6975 28.2498 37.602 28.2498 31.317C28.2498 25.032 23.1543 19.9365 16.8688 19.9365Z" fill="white"/>
                                                        <path d="M16.8694 43.4127C13.9324 43.4127 11.1254 42.3577 8.92144 40.4342L6.57144 41.0202C6.30994 41.0852 6.03394 40.9982 5.85744 40.7942C5.68094 40.5902 5.63444 40.3042 5.73694 40.0552L6.66044 37.8072C5.42494 35.8677 4.77344 33.6327 4.77344 31.3182C4.77344 24.6487 10.1994 19.2227 16.8689 19.2227C23.5384 19.2227 28.9644 24.6487 28.9644 31.3182C28.9649 37.9867 23.5389 43.4127 16.8694 43.4127ZM9.11744 38.9332C9.29594 38.9332 9.47094 39.0002 9.60444 39.1247C11.5844 40.9677 14.1644 41.9827 16.8694 41.9827C22.7504 41.9827 27.5349 37.1982 27.5349 31.3172C27.5349 25.4362 22.7504 20.6517 16.8694 20.6517C10.9884 20.6517 6.20394 25.4362 6.20394 31.3172C6.20394 33.4737 6.84444 35.5507 8.05644 37.3252C8.19244 37.5237 8.21894 37.7777 8.12744 38.0002L7.59744 39.2907L8.94494 38.9547C9.00194 38.9402 9.05994 38.9332 9.11744 38.9332Z" fill="#262262"/>
                                                        <path d="M18.9716 22.6561C23.2728 22.6561 26.7596 19.1693 26.7596 14.8681C26.7596 10.5669 23.2728 7.08008 18.9716 7.08008C14.6704 7.08008 11.1836 10.5669 11.1836 14.8681C11.1836 19.1693 14.6704 22.6561 18.9716 22.6561Z" fill="#FFD15C"/>
                                                        <path d="M19.2849 18.1584C18.8594 18.1584 18.5149 17.8139 18.5149 17.3884V12.8574H18.0669C17.6414 12.8574 17.2969 12.5129 17.2969 12.0874C17.2969 11.6619 17.6414 11.3174 18.0669 11.3174H19.2849C19.7104 11.3174 20.0549 11.6619 20.0549 12.0874V17.3884C20.0549 17.8134 19.7099 18.1584 19.2849 18.1584Z" fill="#262262"/>
                                                        <path d="M11.7041 32.4921C12.4855 32.4921 13.1191 31.8586 13.1191 31.0771C13.1191 30.2956 12.4855 29.6621 11.7041 29.6621C10.9226 29.6621 10.2891 30.2956 10.2891 31.0771C10.2891 31.8586 10.9226 32.4921 11.7041 32.4921Z" fill="#FFD15C"/>
                                                        <path d="M17.2041 32.4921C17.9855 32.4921 18.6191 31.8586 18.6191 31.0771C18.6191 30.2956 17.9855 29.6621 17.2041 29.6621C16.4226 29.6621 15.7891 30.2956 15.7891 31.0771C15.7891 31.8586 16.4226 32.4921 17.2041 32.4921Z" fill="#FFD15C"/>
                                                        <path d="M22.7041 32.4921C23.4855 32.4921 24.1191 31.8586 24.1191 31.0771C24.1191 30.2956 23.4855 29.6621 22.7041 29.6621C21.9226 29.6621 21.2891 30.2956 21.2891 31.0771C21.2891 31.8586 21.9226 32.4921 22.7041 32.4921Z" fill="#FFD15C"/>
                                                    </svg>
                                                </FeatureIcon>
                                                <FeatureItemText>
                                                    {page.giftCreatorsFeatureText[0]}
                                                    <BoldP>{page.giftCreatorsFeatureText[1]}</BoldP>
                                                    {page.giftCreatorsFeatureText[2]}
                                                </FeatureItemText>
                                            </FeatureItem>

                                            <FeatureItem className="right-column-left-align">
                                                <FeatureIcon>
                                                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M18.9736 29.9834H8.05859V45.8059H18.9736V29.9834Z" fill="white"/>
                                                        <path d="M31.0252 16.1406H18.9727V45.8061H31.0252V16.1406Z" fill="white"/>
                                                        <path d="M41.9423 23.2402H31.0273V45.8057H41.9423V23.2402Z" fill="white"/>
                                                        <path d="M25.4652 10.3302L23.3097 11.2432C23.0402 11.3572 22.7457 11.1437 22.7712 10.8517L22.9737 8.51971L21.4392 6.75221C21.2472 6.53121 21.3597 6.18521 21.6452 6.11921L23.9257 5.59121L25.1327 3.58571C25.2837 3.33471 25.6477 3.33471 25.7987 3.58571L27.0057 5.59121L29.2862 6.11921C29.5717 6.18521 29.6837 6.53121 29.4922 6.75221L27.9577 8.51971L28.1602 10.8517C28.1857 11.1437 27.8912 11.3572 27.6217 11.2432L25.4652 10.3302Z" fill="#EFC5E6"/>
                                                        <path d="M25.7036 35.2238C25.2636 35.2238 24.9071 34.8673 24.9071 34.4273V29.0813H24.2926C23.8526 29.0813 23.4961 28.7248 23.4961 28.2848C23.4961 27.8448 23.8526 27.4883 24.2926 27.4883H25.7036C26.1436 27.4883 26.5001 27.8448 26.5001 28.2848V34.4273C26.5001 34.8668 26.1436 35.2238 25.7036 35.2238Z" fill="#262262"/>
                                                        <path d="M18.9736 29.9834H8.05859V35.6134H18.9736V29.9834Z" fill="#EFC5E6"/>
                                                        <path d="M30.7517 16.1406H18.6992V21.7706H30.7517V16.1406Z" fill="#EFC5E6"/>
                                                        <path d="M42.2861 23.2402H31.3711V28.8702H42.2861V23.2402Z" fill="#EFC5E6"/>
                                                        <path d="M18.9732 46.6025H8.05822C7.61822 46.6025 7.26172 46.246 7.26172 45.806V29.983C7.26172 29.543 7.61822 29.1865 8.05822 29.1865H18.9732C19.4132 29.1865 19.7697 29.543 19.7697 29.983V45.8055C19.7697 46.2455 19.4132 46.6025 18.9732 46.6025ZM8.85522 45.009H18.1767V30.7795H8.85522V45.009Z" fill="#262262"/>
                                                        <path d="M31.0253 46.6027H18.9723C18.5323 46.6027 18.1758 46.2462 18.1758 45.8062V16.1402C18.1758 15.7002 18.5323 15.3438 18.9723 15.3438H31.0248C31.4648 15.3438 31.8213 15.7002 31.8213 16.1402V45.8057C31.8218 46.2457 31.4653 46.6027 31.0253 46.6027ZM19.7688 45.0092H30.2288V16.9367H19.7688V45.0092Z" fill="#262262"/>
                                                        <path d="M41.942 46.6028H31.027C30.587 46.6028 30.2305 46.2463 30.2305 45.8063V23.2408C30.2305 22.8008 30.587 22.4443 31.027 22.4443H41.942C42.382 22.4443 42.7385 22.8008 42.7385 23.2408V45.8063C42.7385 46.2458 42.382 46.6028 41.942 46.6028ZM31.824 45.0093H41.1455V24.0373H31.824V45.0093Z" fill="#262262"/>
                                                    </svg>

                                                </FeatureIcon>
                                                <FeatureItemText>
                                                    {page.matchCreatorsFeatureText[0]}
                                                    <BoldP>{page.matchCreatorsFeatureText[1]}</BoldP>
                                                    {page.matchCreatorsFeatureText[2]}
                                                </FeatureItemText>
                                            </FeatureItem>

                                            <FeatureItem className="right-column-left-align">
                                                <FeatureIcon>
                                                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M39.2628 16.7109H12.1948C10.6998 16.7109 9.48828 17.9229 9.48828 19.4174V38.0119C9.48828 39.5069 10.7003 40.7184 12.1948 40.7184H39.2628C40.7578 40.7184 41.9693 39.5064 41.9693 38.0119V19.4174C41.9698 17.9224 40.7578 16.7109 39.2628 16.7109Z" fill="white"/>
                                                        <path d="M39.2633 41.4894H12.1953C10.2783 41.4894 8.71875 39.9299 8.71875 38.0129V19.4179C8.71875 17.5009 10.2783 15.9414 12.1953 15.9414H39.2633C41.1803 15.9414 42.7397 17.5009 42.7397 19.4179V38.0124C42.7402 39.9294 41.1803 41.4894 39.2633 41.4894ZM12.1953 17.4814C11.1273 17.4814 10.2588 18.3504 10.2588 19.4179V38.0124C10.2588 39.0804 11.1278 39.9489 12.1953 39.9489H39.2633C40.3313 39.9489 41.1997 39.0799 41.1997 38.0124V19.4179C41.1997 18.3499 40.3308 17.4814 39.2633 17.4814H12.1953Z" fill="#262262"/>
                                                        <path d="M35.3643 12.9111H8.29634C6.80134 12.9111 5.58984 14.1231 5.58984 15.6176V34.2121C5.58984 35.7071 6.80184 36.9186 8.29634 36.9186H35.3643C36.8593 36.9186 38.0708 35.7066 38.0708 34.2121V15.6176C38.0713 14.1226 36.8593 12.9111 35.3643 12.9111Z" fill="white"/>
                                                        <path d="M35.3648 37.6896H8.29681C6.37981 37.6896 4.82031 36.1301 4.82031 34.2131V15.6181C4.82031 13.7011 6.37981 12.1416 8.29681 12.1416H35.3648C37.2818 12.1416 38.8413 13.7011 38.8413 15.6181V34.2126C38.8418 36.1296 37.2818 37.6896 35.3648 37.6896ZM8.29681 13.6816C7.22881 13.6816 6.36031 14.5506 6.36031 15.6181V34.2126C6.36031 35.2806 7.22931 36.1491 8.29681 36.1491H35.3648C36.4328 36.1491 37.3013 35.2801 37.3013 34.2126V15.6181C37.3013 14.5501 36.4323 13.6816 35.3648 13.6816H8.29681Z" fill="#262262"/>
                                                        <path d="M40.0156 22.0039C43.6055 22.0039 46.5156 19.0938 46.5156 15.5039C46.5156 11.9141 43.6055 9.00391 40.0156 9.00391C36.4258 9.00391 33.5156 11.9141 33.5156 15.5039C33.5156 19.0938 36.4258 22.0039 40.0156 22.0039Z" fill="#EFC5E6"/>
                                                        <path d="M39.5659 17.8125C39.3729 17.8125 39.1879 17.7345 39.0529 17.5955L37.3934 15.8865C37.1184 15.603 37.1249 15.1505 37.4084 14.8755C37.6919 14.6005 38.1444 14.6075 38.4194 14.8905L39.5154 16.0195L41.5594 13.4795C41.8069 13.172 42.2569 13.1235 42.5649 13.3705C42.8724 13.618 42.9214 14.068 42.6739 14.376L40.1229 17.546C39.9944 17.7055 39.8039 17.8025 39.5989 17.812C39.5879 17.8125 39.5769 17.8125 39.5659 17.8125Z" fill="white"/>
                                                        <path d="M19.026 23.6057C20.352 23.6057 21.427 22.5307 21.427 21.2047C21.427 19.8787 20.352 18.8037 19.026 18.8037C17.7 18.8037 16.625 19.8787 16.625 21.2047C16.625 22.5307 17.7 23.6057 19.026 23.6057Z" fill="#EFC5E6"/>
                                                        <path d="M9.82031 32.2269H34.0813L25.3188 23.4639L19.5828 29.1999L15.6983 25.3154L9.82031 32.2269Z" fill="#EFC5E6"/>
                                                    </svg>

                                                </FeatureIcon>
                                                <FeatureItemText>
                                                    {page.captureStoriesFeaturesText}
                                                </FeatureItemText>
                                            </FeatureItem>

                                            <FeatureItem className="right-column-left-align">
                                                <FeatureIcon>
                                                    <img src={ShopifyBag} />
                                                </FeatureIcon>

                                                <FeatureItemText>Connect your Shopify store</FeatureItemText>
                                            </FeatureItem>

                                            {page.completedContentStatsText[0] && (
                                                <FeatureItem className="right-column-left-align">
                                                    <FeatureIcon>
                                                        <img style={{transform: 'translateX(6px)'}} src={CompletedContentStatsIcon} />
                                                    </FeatureIcon>

                                                    <FeatureItemText>{page.completedContentStatsText[0]}</FeatureItemText>
                                                </FeatureItem>
                                            )}

                                        </div>
                                    </>

                                ) : (
                                    <img height={'816px'} width={'315px'} src={SubscriptionSideImg} />
                                )}
                            </RightColumn>
                        </TwoColumns>
                    ) : !page.fullSized ? (
                        <>
                            <OneColumn style={smallDevice ? {background: `url('assets/gifted-shapes.png')`, backgroundSize: 'cover', backgroundPosition: 'center top'} : {}}>
                                <TitleText>{'Welcome to ' + subscriptionService.findPlanDefinition(selectedPlanType).name}</TitleText>
                                <TitleSubText>{page.titleSubtext}</TitleSubText>
                                {!smallDevice ? (
                                    <img src={HorizontalSub} width={1011} />
                                ) : null}

                                <Button className="cta-btn" onClick={() => page.onNext && page.onNext()}>{page.nextButtonText}</Button>

                            </OneColumn>
                        </>
                    ) : (
                        <>
                            <FullSizedPage>

                                {page.showBackBtn && (
                                    <Button className="back-btn" theme="aqua" iconOnly={true} onClick={() => page.onBack && page.onBack()}>
                                        <AngleLeftIcon /> Back
                                    </Button>
                                )}

                                <TitleText>{page.titleText}</TitleText>

                                <TitleSubText>{page.titleSubtext}</TitleSubText>

                                {page.showFilters && (
                                    <>
                                        <FilterBtnRow>
                                            <PaymentIntervalFilterButtons currentlySelected={selectedPlanFrequency} changeFilterItems={(val) => setSelectedPlanFrequency(val)} />
                                        </FilterBtnRow>

                                        {brand.affiliate_id ? (
                                            <TitleSubText style={{
                                                background: 'rgb(255, 184, 0)',
                                                borderRadius: '12px',
                                                width: '475px',
                                                padding: '4px 8px',
                                                marginBottom: '30px',
                                                color: 'white'
                                            }}>As an affiliate you will receive 50% off your first payment!</TitleSubText>
                                        ) : null}
                                    </>
                                )}

                                {currentPage === SubscriptionFlowPageType.THREE_PLAN_PAGE ? (
                                    <>
                                    {settings?.features["subscriptions.trials"] ? (
                                    <table className="plan-table" style={{width: '100%', borderCollapse: 'collapse', marginBottom: '20px'}}>
                                        <tr>
                                            <td style={{padding: '12px', }}></td>
                                            <td style={{padding: '12px', }}></td>
                                            <td style={{padding: '12px', }}></td>
                                            <td className="center-align" style={{padding: '12px', }}><center><div className="green-pill">Best value</div></center></td>
                                        </tr>
                                        <tr>
                                            <td style={{padding: '12px', }}></td>
                                            <td className="center-align plan-table-row price-name" style={{padding: '12px', }}><img style={{height: '50px', width: '50px', transform: 'translateY(12px)'}} src={StarterStar} />#gifted Basic</td>
                                            <td className="center-align gray-1 plan-table-row price-name" style={{padding: '12px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }}><img style={{height: '50px', width: '50px', transform: 'translateY(10px)'}} src={Megaphone} />#gifted Grow</td>
                                            <td className="center-align plan-table-row price-name" style={{padding: '12px', }}><img style={{height: '50px', width: '50px', transform: 'translateY(12px)'}} src={Trophy} />#gifted Pro</td>
                                        </tr>
                                        <tr>
                                            <td style={{padding: '12px', }}></td>
                                            <td className="label-text" style={{padding: '12px', }}>For up and coming brands experimenting with the power of creator marketing.</td>
                                            <td className="gray-1 label-text" style={{padding: '12px', paddingTop: '0px', paddingLeft: '20px' }}>For brands & agencies looking to use creators at scale.</td>
                                            <td className="label-text" style={{padding: '12px', paddingLeft: '20px' }}>For brands and agencies hungering for fresh user generated content.</td>
                                        </tr>
                                        <tr>
                                            <td style={{padding: '12px', }}></td>
                                            <td className="center-align" style={{padding: '12px', }}><span className="price-name">A${selectedPlanFrequency === 'MONTH' ? '99' : '83'}</span><span className="price-name-interval"> /month</span><br /><span className="label-text">Billed {selectedPlanFrequency === 'MONTH' ? 'monthly' : 'annualy'}</span></td>
                                            <td className="center-align gray-1" style={{padding: '12px', }}><span className="price-name">A${selectedPlanFrequency === 'MONTH' ? '149' : '125'}</span><span className="price-name-interval"> /month</span><br /><span className="label-text">Billed {selectedPlanFrequency === 'MONTH' ? 'monthly' : 'annualy'}</span></td>
                                            <td className="center-align" style={{padding: '12px', }}><span className="price-name">A${selectedPlanFrequency === 'MONTH' ? '349' : '293'}</span><span className="price-name-interval"> /month</span><br /><span className="label-text">Billed {selectedPlanFrequency === 'MONTH' ? 'monthly' : 'annualy'}</span></td>
                                        </tr>
                                        <tr className="feature-row">
                                            <td className="gray-1 label-text" style={{padding: '12px', borderBottomLeftRadius: '12px', borderTopLeftRadius: '12px' }}>Campaigns per month</td>
                                            <td className="center-align gray-1 feature-text" style={{padding: '12px', }}>1</td>
                                            <td className="center-align gray-2 feature-text" style={{padding: '12px', }}>3</td>
                                            <td className="center-align gray-1 feature-text" style={{padding: '12px', borderBottomRightRadius: '12px', borderTopRightRadius: '12px' }}>Unlimited</td>
                                        </tr>
                                        <tr className="feature-row">
                                            <td className="label-text" style={{padding: '12px',  }}>Per campaign, <span style={{fontWeight: '600'}}>match with creators</span>, up to</td>
                                            <td className="center-align feature-text" style={{padding: '12px', }}>30</td>
                                            <td className="center-align gray-1 feature-text" style={{padding: '12px', }}>90</td>
                                            <td className="center-align feature-text" style={{padding: '12px',  }}>Unlimited</td>
                                        </tr>
                                        <tr className="feature-row">
                                            <td className="gray-1 label-text" style={{padding: '12px', borderBottomLeftRadius: '12px', borderTopLeftRadius: '12px'}}>Per campaign, <span style={{fontWeight: '600'}}>gift creators</span>, up to</td>
                                            <td className="center-align gray-1 feature-text" style={{padding: '12px', }}>10</td>
                                            <td className="center-align gray-2 feature-text" style={{padding: '12px', }}>30</td>
                                            <td className="center-align gray-1 feature-text" style={{padding: '12px',borderBottomRightRadius: '12px', borderTopRightRadius: '12px' }}>Unlimited</td>
                                        </tr>
                                        <tr className="feature-row">
                                            <td className="label-text" style={{padding: '12px', }}>Auto capture of Instagram, Youtube, and TikTok Content</td>
                                            <td className="center-align feature-text" style={{padding: '12px', }}><span style={{fontWeight: '600'}}>Does <span style={{fontWeight: '700'}}>not</span> include <br /> Instagram stories</span></td>
                                            <td className="center-align gray-1" style={{padding: '12px', }}><img className="check-cross-icon" src={Check} /></td>
                                            <td className="center-align" style={{padding: '12px', }}><img className="check-cross-icon" src={Check} /></td>
                                        </tr>
                                        <tr className="feature-row">
                                            <td className="gray-1 label-text" style={{padding: '12px', borderBottomLeftRadius: '12px', borderTopLeftRadius: '12px' }}>Granular targeting (age, gender, location etc)</td>
                                            <td className="center-align gray-1" style={{padding: '12px', }}><img className="check-cross-icon" src={Cross} /></td>
                                            <td className="center-align gray-2" style={{padding: '12px', }}><img className="check-cross-icon" src={Check} /></td>
                                            <td className="center-align gray-1" style={{padding: '12px', borderBottomRightRadius: '12px', borderTopRightRadius: '12px' }}><img className="check-cross-icon" src={Check} /></td>
                                        </tr>
                                        <tr className="feature-row">
                                            <td className="label-text" style={{padding: '12px', }}>Content licensing (use of content for ads & amplification)</td>
                                            <td className="center-align" style={{padding: '12px', }}><img className="check-cross-icon" src={Cross} /></td>
                                            <td className="center-align gray-1" style={{padding: '12px', }}><img className="check-cross-icon" src={Cross} /></td>
                                            <td className="center-align" style={{padding: '12px', }}><img className="check-cross-icon" src={Check} /></td>
                                        </tr>
                                        <tr className="feature-row">
                                            <td className="gray-1 label-text" style={{padding: '12px', borderBottomLeftRadius: '12px', borderTopLeftRadius: '12px' }}>Measure post performance (reach, engagement, likes)</td>
                                            <td className="center-align gray-1" style={{padding: '12px', }}><img className="check-cross-icon" src={Cross} /></td>
                                            <td className="center-align gray-2" style={{padding: '12px', }}><img className="check-cross-icon" src={Cross} /></td>
                                            <td className="center-align gray-1" style={{padding: '12px', borderBottomRightRadius: '12px', borderTopRightRadius: '12px' }}><img className="check-cross-icon" src={Check} /></td>
                                        </tr>
                                        <tr className="feature-row">
                                            <td className="label-text" style={{padding: '12px', }}>Priority customer support</td>
                                            <td className="center-align" style={{padding: '12px', }}><img className="check-cross-icon" src={Cross} /></td>
                                            <td className="center-align gray-1" style={{padding: '12px', }}><img className="check-cross-icon" src={Cross} /></td>
                                            <td className="center-align" style={{padding: '12px', }}><img className="check-cross-icon" src={Check} /></td>
                                        </tr>
                                        <tr className="feature-row">
                                            <td className="gray-1 label-text" style={{padding: '12px', borderBottomLeftRadius: '12px', borderTopLeftRadius: '12px' }}>Exposure boost (creators see your campaign first)</td>
                                            <td className="center-align gray-1" style={{padding: '12px', }}><img className="check-cross-icon" src={Cross} /></td>
                                            <td className="center-align gray-2" style={{padding: '12px' }}><img className="check-cross-icon" src={Cross} /></td>
                                            <td className="center-align gray-1" style={{padding: '12px', borderBottomRightRadius: '12px', borderTopRightRadius: '12px' }}><img className="check-cross-icon" src={Check} /></td>
                                        </tr>
                                        <tr>
                                            <td style={{padding: '6px', }}></td>
                                            <td className="center-align" style={{padding: '12px', }}></td>
                                            <td className="center-align gray-1" style={{padding: '12px', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px' }}></td>
                                            <td className="center-align" style={{padding: '12px', }}></td>
                                        </tr>
                                        <tr>
                                            <td style={{padding: '8px', }}></td>
                                            <td style={{padding: '8px', }} className="center-align no-contract" >No contract, no lock-ins</td>
                                            <td style={{padding: '8px', }} className="center-align no-contract" >No contract, no lock-ins</td>
                                            <td style={{padding: '8px', }} className="center-align no-contract" >No contract, no lock-ins</td>
                                        </tr>
                                        <tr>
                                            <td style={{padding: '0px', }}></td>
                                            <td className="center-align" style={{padding: '0px', }}><center><Button disabled={!brand.subscription?.canceled_at && brand.subscriptionv2?.status !== "incomplete" && brand.subscriptionv2?.status !== 'canceled' && currentPlan === PlanType.STARTER && currentPlanFrequency === selectedPlanFrequency && brand.subscriptionv2?.status !== 'trialing'} className="cta-btn" onClick={() => paymentNextPageBtn(PlanType.STARTER, selectedPlanFrequency)}>
                                                {!brand.subscription?.canceled_at && brand.subscriptionv2?.status !== "incomplete" && brand.subscriptionv2?.status !== 'canceled' && currentPlan === PlanType.STARTER && currentPlanFrequency === selectedPlanFrequency 
                                                    ? brand.subscriptionv2?.status === 'trialing' 
                                                        ? 'End Trial and Subscribe'
                                                        : 'You are on this plan' 
                                                    : isTrialAvailable 
                                                        ? 'Start a 7-day free trial' 
                                                        : 'Sign up for #gifted Basic'}
                                            </Button></center></td>
                                            <td className="center-align" style={{padding: '0px', }}><center><Button disabled={!brand.subscription?.canceled_at && brand.subscriptionv2?.status !== "incomplete" && brand.subscriptionv2?.status !== 'canceled' && currentPlan === PlanType.GROW && currentPlanFrequency === selectedPlanFrequency && brand.subscriptionv2?.status !== 'trialing'} className="cta-btn" onClick={() => paymentNextPageBtn(PlanType.GROW, selectedPlanFrequency)}>
                                                {!brand.subscription?.canceled_at && brand.subscriptionv2?.status !== "incomplete" && brand.subscriptionv2?.status !== 'canceled' && currentPlan === PlanType.GROW && currentPlanFrequency === selectedPlanFrequency
                                                    ? brand.subscriptionv2?.status === 'trialing'
                                                        ? 'End Trial and Subscribe'
                                                        : 'You are on this plan'
                                                    : isTrialAvailable 
                                                        ? 'Start a 7-day free trial' 
                                                        : 'Sign up for #gifted Grow'}
                                            </Button></center></td>
                                            <td className="center-align" style={{padding: '0px', }}><center><Button disabled={!brand.subscription?.canceled_at && brand.subscriptionv2?.status !== "incomplete" && brand.subscriptionv2?.status !== 'canceled' && currentPlan === PlanType.PRO && currentPlanFrequency === selectedPlanFrequency && brand.subscriptionv2?.status !== 'trialing'} className="cta-btn" onClick={() => paymentNextPageBtn(PlanType.PRO, selectedPlanFrequency)}>
                                                {!brand.subscription?.canceled_at && brand.subscriptionv2?.status !== "incomplete" && brand.subscriptionv2?.status !== 'canceled' && currentPlan === PlanType.PRO && currentPlanFrequency === selectedPlanFrequency
                                                    ? brand.subscriptionv2?.status === 'trialing'
                                                        ? 'End Trial and Subscribe'
                                                        : 'You are on this plan'
                                                    : isTrialAvailable 
                                                        ? 'Start a 7-day free trial' 
                                                        : 'Sign up for #gifted Pro'}
                                            </Button></center></td>
                                        </tr>
                                    </table>
                                    ) : (
                                    <ThreePlansBox>
                                        <PlanColumn
                                            title="#gifted Basic"
                                            plan={PlanType.STARTER}
                                            description="For up and coming brands experimenting with the power of creator marketing."
                                            alreadyActive={!brand.subscription?.canceled_at && brand.subscriptionv2?.status !== "incomplete" && brand.subscriptionv2?.status !== 'canceled' && currentPlan === PlanType.STARTER && currentPlanFrequency === selectedPlanFrequency}
                                            selectedPlanFrequency={selectedPlanFrequency}
                                            onNext={() => page.onNext && page.onNext()}
                                            setPlanAndInterval={(plan, interval) => setPlanAndInterval(plan, interval)}
                                        />
                                        <PlanColumn
                                            title="#gifted Grow"
                                            plan={PlanType.GROW}
                                            description="For brands & agencies looking to use creators at scale."
                                            alreadyActive={!brand.subscription?.canceled_at  && brand.subscriptionv2?.status !== "incomplete" && brand.subscriptionv2?.status !== 'canceled' && (currentPlan === PlanType.GROW && currentPlanFrequency === selectedPlanFrequency)}
                                            selectedPlanFrequency={selectedPlanFrequency}
                                            onNext={() => page.onNext && page.onNext()}
                                            setPlanAndInterval={(plan, interval) => setPlanAndInterval(plan, interval)}
                                        />
                                        <PlanColumn
                                            title="#gifted Pro"
                                            plan={PlanType.PRO}
                                            alreadyActive={!brand.subscription?.canceled_at  && brand.subscriptionv2?.status !== "incomplete" && brand.subscriptionv2?.status !== 'canceled' && currentPlan === PlanType.PRO && currentPlanFrequency === selectedPlanFrequency}
                                            description="For brands and agencies hungering for fresh user generated content."
                                            selectedPlanFrequency={selectedPlanFrequency}
                                            onNext={() => page.onNext && page.onNext()}
                                            setPlanAndInterval={(plan, interval) => setPlanAndInterval(plan, interval)}
                                        />
                                    </ThreePlansBox>
                                    )}
                                    </>
                                ) : null}

                                {currentPage === SubscriptionFlowPageType.FULL_SIZED_PAYMENT_PAGE ? (
                                    <FullPaymentPageColumns>

                                        <FullPagePaymentColumn>
                                            <PaymentInputFields>


                                                <TitleText style={{fontSize: '31px'}}>Subscribe to #gifted {getSubscriptionPlanName(selectedPlanType)}</TitleText>

                                                <TitleSubText>You have selected a {selectedPlanFrequency === 'YEAR' ? 'yearly' : 'monthly' } subscription</TitleSubText>


                                                <CheckoutForm
                                                    brand={brand}
                                                    shownInModal={true}
                                                    interval={selectedPlanFrequency}
                                                    plan={selectedPlanType}
                                                    previewSubscription={previewSubscription}
                                                    showLoader={false}
                                                    code={selectedSubscription.code}
                                                    onSuccess={onSuccess}
                                                    onCancel={onCancel}
                                                    error={previewError}
                                                />
                                            </PaymentInputFields>

                                            <LeftSectionFooter style={{marginTop: '-81px'}}>
                                                <DueBlock>
                                                    <DueRow>
                                                        <p>Due {trialDays || isTrialAvailable ? trialEndDate : 'today'}</p>
                                                        <p>
                                                            <span
                                                                style={{textDecoration: (newTotal && newTotal.toFixed(2) !== selectedSubscription.amount.toFixed(2) || newTotal === 0) && !trialDays ? 'line-through' : ''}}
                                                            >
                                                                A${parseFloat(selectedSubscription.amount.toFixed(2)).toLocaleString()}
                                                            </span>
                                                            {(newTotal && newTotal.toFixed(2) !== selectedSubscription.amount.toFixed(2) || newTotal === 0) && !trialDays  ? (
                                                                <span
                                                                    style={{color: 'var(--blue)', fontWeight: '600'}}
                                                                >
                                                                    A${parseFloat(newTotal.toFixed(2)).toLocaleString()}
                                                                </span>
                                                            ) : null}</p>

                                                    </DueRow>
                                                    {((trialDays || isTrialAvailable) && settings?.features["subscriptions.trials"]) && (
                                                        <DueRow>
                                                            <p className="due-row-bolder">Due today - <span className="due-row-support">{trialDays || subscriptionCandidate.trial_days} Days free</span></p>
                                                            <p className="due-row-bolder">A$0</p>
                                                        </DueRow>
                                                    )}
                                                </DueBlock>
                                            </LeftSectionFooter>
                                        </FullPagePaymentColumn>

                                        <FullPageTestimonials>
                                            {trialDays || isTrialAvailable ? (
                                                <>
                                                    <TrialInfo />
                                                    <TestimonialsBox amount={1} />
                                                </>
                                            ) : <TestimonialsBox amount={3} />}
                                        </FullPageTestimonials>


                                    </FullPaymentPageColumns>
                                ) : null}



                            </FullSizedPage>


                        </>
                    )}

                </>
            )}
        </>
    );
}

const FullPaymentPageColumns = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    margin-top: 40px;

    @media (max-width:900px) and (min-width:0px) {
        flex-wrap: wrap;
        width: 100%;
        max-width: 100%;
    }
`

const FullPagePaymentColumn = styled.div`
    // height: 760px;
    box-shadow: 0 0 2px rgb(65 62 62 / 63%);
    background: white;
    border-radius: 14px;
    padding: 20px 0px;
    margin-right: 30px;
    // min-height: 820px;

    @media (max-width:900px) and (min-width:0px) {
        with: 100%;
        margin: 0px;
        box-shadow: none;
        padding: 0px;
    }
`

const FullPageTestimonials = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 30px;

    @media (max-width:900px) and (min-width:0px) {
        margin-top: 80px;
        margin-left: 0px;
    }
`


const FullSizedPage = styled.div`
    width: 100vw;
    min-height: 100vh;
    background: white;
    position: absolute;
    top: 0;
    left: 0;
    background: url('/assets/gifted-shapes.png');
    background-color: white;
    background-size: contain;
    background-repeat: repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;

    .back-btn {
        position: absolute;
        top: 40px;
        left: 40px;

        @media (max-width:900px) and (min-width:0px) {
            top: 10px;
            left: 30px;
            padding: 0px 10px;
            padding-right: 17px;
            height: 34px;
        }
    }

    .plan-table {
        background: white;
        max-width: 1221px;

        .gray-1 {
            background: #fafafa;
        }

        .gray-2 {
            background: #f7f7f7;
        }

        .plan-table-row {
            width: 312px;
        }

        .feature-row {
            height: 64px;
        }

        .check-cross-icon {
            width: 32px;
            height: 32px;
        }

        .center-align {
            text-align: center;
        }

        .price-name {
            font-size: 32px;
            color: var(--blue);
            font-weight: 700;
        }

        .price-name-interval {
            font-size: 24px;
            color: var(--blue);
            font-weight: 600;
        }

        .label-text {
            font-size: 16px;
            color: var(--blue);
            font-weight: 500;
        }

        .feature-text {
            font-size: 20;
            font-weight: 600;
            color: var(--blue);
        }

        .shorter {
            width: 100px;
        }

        .no-contract {
            font-size: 14px;
            font-weight: 600;
            color: var(--blue);
        }

        .green-pill {
            background: rgb(116, 215, 194);
            color: black;
            border-radius: 20px;
            padding: 4px 18px;
            font-size: 14px;
            font-weight: 600;
            width: fit-content;
        }

        button {
            &:disabled {
                cursor: not-allowed;
            }
        }
    }

    @media (max-width:900px) and (min-width:0px) {
        height: 100vh;
        overflow-y: scroll;
        background: white;
        padding: 40px 20px;
    }
`

const ThreePlansBox = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    
    @media (max-width:900px) and (min-width:0px) {
        flex-wrap: wrap;
    }
`

const TwoColumns = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`

const OneColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 64px;

    .cta-btn {
        width: 398px;
        margin-top: 128px;
    }

    @media (max-width:900px) and (min-width:0px) {
        img {
            max-width: 100% !important;
        }

        .cta-btn {
            width: 90%;
        }
    }
`

const LeftColumn = styled.div`
    position: relative;
    width: 532px;
    height: 100%;
    border-right: solid 2px #E1E7F4;
    padding-top: 15px;
    padding-bottom: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: auto;

    .back-btn {
        margin-left: 33px;
        width: 52px;
        height: 44px;
        padding: 14px !important;

        svg {
            height: 15px;
            width: 15px;
        }
    }

    .cta-btn {
        width: 398px;
        margin-left: 64px;
        // margin-top: 128px;

        &.absoluted {
            position: absolute;
            bottom: 24px;
        }
    }
`

const RightColumn = styled.div`
    width: 468px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .gifted-logo {
        margin-top: 105px;
        margin-bottom: 27px;
    }
`

const TitleText = styled.h1`
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    letter-spacing: -1px;
    color: #262161;
    width: 100%;
    margin-bottom: 0px;
    padding-bottom: 0px;
`

const TitleSubText = styled.h3`
    padding-top: 0px;
    margin-top: 12px;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -1px;
    color: #262161;
    width: 100%;
`

const FeatureDescriptionText = styled.p`
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    margin-left: 64px;
    margin-right: 64px;
    margin-bottom: 16px;

    &.right-column-left-align {
        text-align: center;
        padding-left: 37px;
        margin-left: 16px;
        margin-right: 16px;
    }
`

const BoldP = styled.span`
    font-weight: 600;
`

const FeatureItem =  styled.div`
    padding-left: 64px;
    display: flex;
    align-items: center;
    // max-width: 470px;
    height: 45px;
    margin-bottom: 10px;

    &.right-column-left-align {
        padding-left: 37px;
    }
`

const FeatureIcon = styled.div`
    margin-right: 35px;
    width: 45px;
    height: 45px;

    img {
        width: 45px;
        height: 45px;    
    }
`

const FeatureItemText = styled.p`
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    width: 79%;
    color: #000000;
`

const PriceOption = styled.div`
    margin-top: 15px;
    display: flex;
    align-items: flex-start;
    height: 64px;
    margin-left: 92px;

    input {
        margin-top: 10px;
    }
`

const PriceOptionContent = styled.div`
    margin-left: 15px;
    color: #262161;

`

const PriceOptionAmount = styled.p`
    font-weight: 600;
    margin: 0;
    padding: 0;
    font-size: 20px;
    line-height: 32px;
`

const PriceOptionSubtext = styled.p`
    margin: 0;
    padding: 0;
    color: #262161;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
`

const PriceSpan = styled.span`
    margin-left: 8px;
    color: white;
    font-size: 12px;
    font-weight: 500;
    padding: 5px 16px;
    background: #FFB800;
    border-radius: 8px;
`

const LeftSectionFooter = styled.div`
    width: 100%;
    border-top: solid 2px #E1E7F4;
    margin-top: 120px;

    @media (max-width:900px) and (min-width:0px) {
        margin-top: 0px;
        padding-bottom: 64px;
    }
`

const DueBlock = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 63px;
`

const DueRow = styled.div`
    display: flex;
    justify-content: space-between;

    p {
        margin: 0;
        padding: 0;
        margin-bottom: 5px;
        color: var(--grey-9);
    }

    .due-row-bolder {
        color: #262161;
        font-weight: 600;
    }

    .due-row-support {
        font-weight: 400;
        color: #75D6C2;
    }
`

const PaymentInputFields = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 70px;
    width: 90%;


    .payment-pg-input {
        margin-bottom: 15px;
    }

    .half-width-input {
        width: 45%;
    }

    @media (max-width:900px) and (min-width:0px) {
        flex-wrap: wrap;
        width: 100%;
        padding: 0px 8px;

        &.half-width-input {
            width: 100%;
        }
    }
`

const FilterBtnRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 8px;
    margin-bottom: 20px;
`
